<template>
    <div class="container">

        <div class="row">

            <div class="col-lg-12">
                <v-breadcrumbs :items="breadcumbs">
                    <template v-slot:item="{ item }">
                        <v-breadcrumbs-item
                            :to="item.href"
                            class="text-subtitle-2 crumb-item"
                            :disabled="item.disabled"
                            exact
                        >
                            {{ item.text }}
                        </v-breadcrumbs-item>
                    </template>
                </v-breadcrumbs>
            </div>

            <div class="col-lg-12">
                <div class="card bgCustom">
                    <div class="card-body">
                        <v-spacer></v-spacer>
                        <div class="row">
                            <div class="col-lg-3">
                                <v-select v-model="millModel" :items="millLists" default="" item-value="mill_id" item-text="mill_name" label="Select Mill" clearable dense prepend-icon="mdi-factory"></v-select>
                            </div>
                            <div class="col-lg-3">
                                <v-dialog ref="dialog" v-model="modalDateFrom" :return-value.sync="dateFrom" persistent width="290px" >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateFrom" label="Start Period" readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateFrom" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateFrom = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog.save(dateFrom)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-3">
                                <v-dialog ref="dialog2" v-model="modalDateTo" :return-value.sync="dateTo" persistent width="290px">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field v-model="dateTo" label="End Period" readonly v-bind="attrs" v-on="on" clearable prepend-icon="mdi-calendar-check"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="dateTo" type="month" scrollable>
                                        <v-spacer></v-spacer>
                                        <v-btn text color="primary" @click="modalDateTo = false">Cancel</v-btn>
                                        <v-btn text color="primary" @click="$refs.dialog2.save(dateTo)">OK</v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </div>
                            <div class="col-lg-3 text-right">
                                <v-btn class="mr-4" color="primary" elevation="2" large rounded @click="getChartData()">Search<v-icon right dark>mdi-magnify</v-icon></v-btn>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 cardGraph1">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer1" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 cardGraph2">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer2" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 cardGraph3">
                <div class="card bgCustom2">
                    <div class="card-body">
                        <div id="chartContainer3" style="height: 350px; width: 100%;"></div>
                    </div>
                </div>
            </div>

        </div>

    </div>
    
</template>

<script>

export default {
    data() {
        return {
            breadcumbs: [
                {
                text: 'KKA',
                disabled: false,
                href: '/admin/kka',
                },
                {
                text: 'KKA Analysis',
                disabled: false,
                href: '#',
                },
                {
                text: 'Shipping Analysis',
                disabled: true,
                href: '#',
                },
            ],
            millModel: '',
            millLists: [],
            dateFrom: '',
            modalDateFrom: false,
            dateTo: '',
            modalDateTo: false,
        }
    },
    async mounted(){

        this.initialize()        

    },
    methods:{
        
        async initialize(){

            this.$store.dispatch('setOverlay', true)

            document.querySelector(".cardGraph1").style.display = "none";
            document.querySelector(".cardGraph2").style.display = "none";
            document.querySelector(".cardGraph3").style.display = "none";

            this.dateFrom = this.currentPeriod()
            this.dateTo = this.currentPeriod()

            await axios.get(`${process.env.VUE_APP_URL}/api/kka/ShippingAnalysis`, { 
                headers: { 
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                } 
            })
            .then(res => {
                this.millLists = res.data.mill
                this.$store.dispatch('setOverlay', false)
            })

        },

        async getChartData(){

            if(this.millModel == null || this.millModel == ''){
                Swal.fire({
                        text: 'Please Select Mill',
                        icon: 'error',
                    })
                return false
            }

            this.$store.dispatch('setOverlay', true)

            if (this.dateFrom) {
                this.dateFromFormatted = new Date(this.dateFrom).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateFromFormatted = ''
            }

            if (this.dateTo) {
                this.dateToFormatted = new Date(this.dateTo).toISOString().substr(0, 7).replace(/-/g,"")
            } else  {
                this.dateToFormatted = ''
            }

            await axios.post(`${process.env.VUE_APP_URL}/api/kka/ShippingAnalysis/getChartData`, { 

                    mill: this.millModel ? this.millModel : '',
                    dateFrom: this.dateFromFormatted ? this.dateFromFormatted : '',
                    dateTo: this.dateToFormatted ? this.dateToFormatted : '',

                },
                {
                    headers: {
                        'Access-Control-Allow-Origin': '*',
                        Authorization: `Bearer ${this.$store.getters.isTokenUser}`
                    } 
                }
            )
            .then(res => {

                if(res.data.arr_list1.length != 0){
                    document.querySelector(".cardGraph1").style.display = "block";
                    this.renderChart1(res.data.arr_list1, res.data.arr_list2)
                    this.$store.dispatch('setOverlay', false)
                } else {
                    this.$store.dispatch('setOverlay', false)
                    document.querySelector(".cardGraph1").style.display = "none";
                    Swal.fire({
                        text: 'Data not found !',
                        icon: 'error',
                    })
                }
                
            })

        },

        async getChartDataDetails(e){

            this.$store.dispatch('setOverlay', true)
            document.querySelector(".cardGraph2").style.display = "none";
            document.querySelector(".cardGraph3").style.display = "none";

            await axios.post(`${process.env.VUE_APP_URL}/api/kka/ShippingAnalysis/getChartDataDetails`, { 

                mill: this.millModel ? this.millModel : '',
                period: e.dataPoint.label

            },
            {
            headers: {
                'Access-Control-Allow-Origin': '*',
                Authorization: `Bearer ${this.$store.getters.isTokenUser}`
            } 
            }).then(res => {

                this.$store.dispatch('setOverlay', false)
                document.querySelector(".cardGraph2").style.display = "block";
                document.querySelector(".cardGraph3").style.display = "block";
                this.renderChart2(res.data.arr_list1)
                this.renderChart3(res.data.arr_list2)

            })

        },

        renderChart1(data1, data2){

            var chart1 = new CanvasJS.Chart("chartContainer1", {
                animationEnabled: true,
                exportEnabled: false,
                theme: "light2",
                title: {
                    text: "Shipping Analysis Graph",
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                axisY: {
                    crosshair: {
                        enabled: true,
                        snapToDataPoint: true
                    },
                    title: "Weight in Ton",
                    labelFormatter: this.addSymbols,
                },
                axisY2: {
                    title: "Count. Deliv",
                    titleFontColor: "#0f4c75",
                    lineColor: "#0f4c75",
                    labelFontColor: "#0f4c75",
                    tickColor: "#0f4c75",
                    includeZero: true,
                    labelFormatter: this.addSymbols
                },
                toolTip:{
                    shared:true
                },
                legend: {
                    cursor: "pointer",
                    itemclick: this.toggleDataSeries
                },
                data: [
                    {
                        type: "column",
                        click: this.getChartDataDetails,
                        name: "Weight Ship",
                        showInLegend: true,
                        indexLabel: "{y}",
                        indexLabelPlacement: "inside",
                        indexLabelOrientation: "vertical",
                        indexLabelFontWeight: "bold",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        color: "#42ddf5",
                        dataPoints: data1
                    },
                    {
                        type: "spline",
                        name: "Delivery",
                        axisYType: "secondary",
                        showInLegend: true,
                        indexLabel: "{y}",
                        indexLabelPlacement: "outside",
                        indexLabelOrientation: "horizontal",
                        indexLabelFontWeight: "bold",
                        indexLabelFontSize: 12,
                        indexLabelFontFamily: "calibri",
                        color: "#0f4c75",
                        dataPoints: data2
                    },
                ]
            })

            chart1.render()

        },

        renderChart2(data){

            var chart3 = new CanvasJS.Chart("chartContainer2", {
                animationEnabled: true,
                title: {
                    text: "Shipping Analysis by Mill",
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: false,
                theme: "light2",
                data: [{
                    type: "pie",
                    percentFormatString: "#0.##",
                    indexLabel: "{label} #percent%",
                    indexLabelFontSize: 11,
                    dataPoints: data
                }]
            })

            chart3.render()

        },

        renderChart3(data){

            var chart3 = new CanvasJS.Chart("chartContainer3", {
                animationEnabled: true,
                title: {
                    text: "Shipping Analysis by Customer",
                    fontFamily: "Calibri",
                    fontSize: 20
                },
                exportEnabled: false,
                theme: "light2",
                data: [{
                    type: "pie",
                    percentFormatString: "#0.##",
                    indexLabel: "{label} #percent%",
                    indexLabelFontSize: 11,
                    dataPoints: data
                }]
            })

            chart3.render()

        },

        addSymbols(e) {
            var suffixes = ["", "K", "M", "B"];
            var order = Math.max(Math.floor(Math.log(e.value) / Math.log(1000)), 0);

            if(order > suffixes.length - 1)
                order = suffixes.length - 1;

            var suffix = suffixes[order];
            return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
        },

        toggleDataSeries(e) {
            if (typeof (e.dataSeries.visible) === "undefined" || e.dataSeries.visible) {
                e.dataSeries.visible = false;
            } else {
                e.dataSeries.visible = true;
            }
            e.chart.render();
        },

        currentPeriod() {
            const current = new Date();
            const date = `${current.getFullYear()}-${current.getMonth()+1}`;      
            return date;
        }

    }
    
}
</script>

<style scoped>

    .v-application p {
        margin-bottom: 0px !important;
    }

    .v-text-field {
        padding-top: 0px !important;
    }

    .v-breadcrumbs {
        padding: 5px 5px !important;
    }

    .badgeInfo {
        font-size: 1rem;
    }

    .bgCustom {
        background: linear-gradient(to right, #fff, #f1f1f1) !important;
        color: #fff;
    }

    .bgCustom2 {
        background: #bde0fe !important;
        color: #fff;
    }

    .bgCustom3 {
        background: rgba(19, 64, 116, 0.5);
        color: #fff;
    }

    .container{
        margin-bottom: 60px !important;
    }

</style>